<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm">
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1"></i>
			<span class="download">上传教材/视频</span>
		</div>
		<div class="uploadFile">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
				<div class="uploadFile-item">
					<p class="title">1.上传教材</p>
					<div class="uploadFile-btn" v-if="servicefileList.length < 6">
						<img src="@/assets/imgs/uploadFile.png" alt="" />
						<el-upload
							action=""
							multiple
							:before-upload="beforeUpload"
							:http-request="uploadURL"
							:on-error="importError"
							:file-list="servicefileList"
							:show-file-list="false"
							:limit="6"
						>
							<el-button>选择文件</el-button>
						</el-upload>
						<p class="tips">文件支持格式：PDF</p>
					</div>
					<div class="uploadFile-fileList" v-if="servicefileList.length != 0">
						<p v-for="item in servicefileList" :key="item.uid" class="clearfix">
							<i class="el-icon-document"></i> <span class="one-text-overflow">{{ item.title }}</span>
							<i class="el-icon-close float-r" @click="delServicefileList(item, 'servicefileList')"></i>
						</p>
					</div>
					<el-form-item label="" prop="textbookTime">
						<el-input v-model.trim="ruleForm.textbookTime" type="number" placeholder="请输入" class="timeClass">
							<template slot="append">课时</template>
						</el-input>
					</el-form-item>
				</div>
				<div class="uploadFile-item">
					<p class="title">2.上传视频</p>
					<div class="uploadFile-btn" v-if="fileList.length < 6">
						<img src="@/assets/imgs/uploadMp4.png" alt="" />
						<el-upload
							action=""
							multiple
							:before-upload="beforeUpload1"
							:http-request="uploadURL1"
							:on-error="importError1"
							:file-list="fileList"
							:show-file-list="false"
							:limit="6"
						>
							<el-button>选择文件</el-button>
						</el-upload>
						<p class="tips">文件支持格式：MP4</p>
					</div>
					<div class="uploadFile-fileList" v-if="fileList.length != 0">
						<p v-for="item in fileList" :key="item.uid" class="clearfix">
							<i class="el-icon-document"></i> <span class="one-text-overflow">{{ item.title }}</span>
							<i class="el-icon-close float-r" @click="delServicefileList(item, 'fileList')"></i>
						</p>
					</div>
					<el-form-item label="" prop="videoTime">
						<el-input v-model.trim="ruleForm.videoTime" type="number" placeholder="请输入" class="timeClass">
							<template slot="append">课时</template>
						</el-input>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			servicefileList: [],
			fileList: [],
			saveLoading: false,
			link: '',
			attachmentInfo: {},
			ruleForm: {
				textbookTime: '',
				videoTime: '',
			},
			rules: {},
		};
	},
	watch: {},
	created() {},
	methods: {
		init({ row, link }) {
			this.createdVisible = true;
			this.getAttachment();
			this.link = link;
			if (row) {
				this.ruleForm = { ...row };
				this.servicefileList =
					(row.attachmentMap &&
						row.attachmentMap['DOCUMENT'] &&
						row.attachmentMap['DOCUMENT'].map((item) => {
							return {
								url: item.link,
								uid: item.id,
								title: item.title,
							};
						})) ||
					[];
				this.fileList =
					(row.attachmentMap &&
						row.attachmentMap['VIDEO'] &&
						row.attachmentMap['VIDEO'].map((item) => {
							return {
								url: item.link,
								uid: item.id,
								title: item.title,
							};
						})) ||
					[];
				this.$set(
					this.ruleForm,
					'textbookTime',
					(row.attachmentMap && row.attachmentMap['DOCUMENT'] && row.attachmentMap['DOCUMENT'][0].description) || ''
				);
				this.$set(this.ruleForm, 'videoTime', (row.attachmentMap && row.attachmentMap['VIDEO'] && row.attachmentMap['VIDEO'][0].description) || '');
				this.$forceUpdate();
			}
		},
		getAttachment() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'LECTURE_LESSON' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		delServicefileList(item, type) {
			this[type] = this[type].filter((v) => v.uid !== item.uid);
		},
		// 上传服务内容及条款
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_INSTRUCTION' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.servicefileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
							byteCount: file.file.size,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 上传服务须知
		uploadURL1(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_TERM' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
							byteCount: file.file.size,
						});
					}
				});
		},
		beforeUpload1(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.mp4') {
				this.$message({
					type: 'error',
					message: '请导入mp4文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError1(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 重置
		onResetForm() {
			this.createdVisible = false;
			this.servicefileList = [];
			this.fileList = [];
			this.$nextTick(() => {
				this.$refs.ruleForm.resetFields();
			});
		},
		// 保存
		onSave(formName) {
			if (this.servicefileList.length == 0 && this.fileList.length == 0 && this.ruleForm.textbookTime == '' && this.ruleForm.videoTime == '') {
				this.$message.error('请上传文件或视频');
				return;
			} else if (this.ruleForm.textbookTime != '' && this.ruleForm.videoTime != '' && this.servicefileList.length == 0 && this.fileList.length == 0) {
				this.$message.error('请上传课时对应文件或视频');
				return;
			} else if (this.ruleForm.textbookTime != '' && this.servicefileList.length == 0) {
				this.$message.error('请上传课时对应文件');
				return;
			} else if (this.ruleForm.videoTime != '' && this.fileList.length == 0) {
				this.$message.error('请上传课时对应视频');
				return;
			} else if (this.servicefileList.length != 0 && this.fileList.length == 0) {
				this.rules = {
					textbookTime: [{ required: true, message: '请输入文件课时', trigger: 'blur' }],
				};
			} else if (this.servicefileList.length == 0 && this.fileList.length != 0) {
				this.rules = {
					videoTime: [{ required: true, message: '请输入视频课时', trigger: 'blur' }],
				};
			} else if (this.servicefileList.length != 0 && this.fileList.length != 0) {
				this.rules = {
					textbookTime: [{ required: true, message: '请输入文件课时', trigger: 'blur' }],
					videoTime: [{ required: true, message: '请输入视频课时', trigger: 'blur' }],
				};
			} else if (
				(this.servicefileList.length == 0 && this.ruleForm.textbookTime == '') ||
				(this.fileList.length == 0 && this.ruleForm.videoTime == '')
			) {
				this.rules = {};
			}
			this.$nextTick(() => {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.saveLoading = true;
						let fileList1 = this.servicefileList.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').id,
								mimeType: item.title.split('.')[1],
								description: this.ruleForm.textbookTime || '',
								byteCount: item.byteCount,
							};
						});
						let fileList2 = this.fileList.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'VIDEO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'VIDEO').id,
								mimeType: item.title.split('.')[1],
								description: this.ruleForm.videoTime || '',
								byteCount: item.byteCount,
							};
						});
						let imgs = this.ruleForm.attachmentMap && this.ruleForm.attachmentMap['PHOTO'] ? this.ruleForm.attachmentMap['PHOTO'] : [];
						console.log(this.ruleForm, imgs, fileList1, fileList2, 'ruleForm');
						debugger;
						let params = {
							...this.ruleForm,
							attachments: [...fileList1, ...fileList2, ...imgs],
						};
						let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
						promiseUrl
							.then((res) => {
								if (res.data && res.data.success) {
									this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
									this.$emit('getList');
									this.onResetForm();
									this.saveLoading = false;
								}
							})
							.catch((e) => {
								this.saveLoading = false;
							});
					}
				});
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
.uploadFile {
	.title {
		color: #333;
		line-height: 14px;
		height: 14px;
		margin-bottom: 20px;
	}
	&-item {
		background-color: #f8f9fa;
		padding: 24px;
		margin-bottom: 12px;
		padding-bottom: 10px;
		.timeClass {
			width: 180px;
		}
		/deep/ .el-input-group__append {
			padding: 0 8px !important;
		}
	}
	&-btn {
		background-color: #fff;
		padding: 32px 0;
		text-align: center;
		margin-bottom: 20px;
		img {
			margin-bottom: 12px;
			width: 48px;
			height: 48px;
		}
		.tips {
			margin-top: 12px;
			color: #999;
			line-height: 14px;
			height: 14px;
		}
	}
	&-fileList {
		margin-bottom: 10px;
		p {
			margin-top: 12px;
			color: #1db9b1;
			height: 14px;
			line-height: 14px;
			span {
				display: inline-block;
				width: 380px;
			}
			.el-icon-document {
				margin-right: 7px;
			}
			.el-icon-close {
				display: none;
			}
			&:hover {
				.el-icon-close {
					display: block;
					cursor: pointer;
				}
			}
		}
	}
}
</style>